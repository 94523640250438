<template>
	<div>
		<div class="talent row font-normal" :class="type == 'recommend' ? 'talent-recommend' : ''" v-if="item.user">
			<div class="row pointer" @click="submitClick('/layout/mangeCv',item.user_id,item.position,item.type,item.position_id)">
				<img :src="item.user.avatar_url ? item.user.avatar_url : avatar_url" class="user-avatar" alt="" />
				<div class="user-detail">
					<div class="el-example-img" v-if="item.type == 3">
						<img src="../../../assets/glxt/46.png" alt="" />
					</div>
					<div class="user-name row-center">
						<span v-if="item.user.privates.protect_name == 0">{{ item.user.name }}</span>
						<span v-else>
							{{ item.user.name.slice(0, 1)}}{{ item.user.gender.value == 1 ? "先生" : "女士" }}
						</span>
						<div class="text-blue flex-center" v-if="type !== 'recommend'">
							{{ item.type == 1 ? "平台推送" : "自主投递" }}
						</div>
						<span v-if="type !== 'recommend'">{{ item.online_status }}</span>
					</div>
					<div class="user-year-job row-center">
						<span>{{ item.user.gender.text }}</span>
						<div>|</div>
						<span>{{ item.user.other_info.age }}</span>
						<div>|</div>
						<span>工作{{ item.user.other_info.work_year }}年</span>
						<div>|</div>
						<span>{{ item.user.highest_education }}</span>
						<div>|</div>
						<span>{{ item.user.other_info.job_status }}</span>
					</div>
					<div class="talent-expect row-center" v-if="item.user.job_intention.length > 0">
						<span>期望：</span>
						<span>{{ item.user.job_intention[0].province }}</span>
						<div>|</div>
						<span>
						{{item.user.job_intention[0].salary_min }}-{{item.user.job_intention[0].salary_max}}
						</span>
					</div>
					<div v-if="type !== 'recommend'">
						{{ item.type == 1 ? "系统匹配职位" : "投递职位" }}：{{ item.position }}
					</div>
				</div>
			</div>
			<div class="talent-middle pointer" @click="submitClick('/layout/mangeCv',item.user_id,item.position,item.type,item.position_id)">
				<div class="row work-talent">
					<img src="../../../assets/glxt/20.png" alt="" v-if="item.user.work.length > 0" />
					<div v-if="item.user.work.length > 0">
						<div class="work-time row-center">
							<div>{{ item.user.work[0].job_times }}</div>
							<div>{{ item.user.work[0].company_name }}</div>
							<div>{{ item.user.work[0].work_name }}</div>
						</div>
						<div class="work-time row-center" v-if="item.user.work.length > 1">
							<div>{{ item.user.work[1].job_times }}</div>
							<div>{{ item.user.work[1].company_name }}</div>
							<div>{{ item.user.work[1].work_name }}</div>
						</div>
					</div>
				</div>
				<div class="row work-talent">
					<img src="../../../assets/glxt/5.png" alt="" v-if="item.user.school.length > 0" />
					<div>
						<div class="work-time row-center" v-if="item.user.school.length > 0">
							<div>{{ item.user.school[0].school_times }}</div>
							<div class="space-between">
								<span>{{ item.user.school[0].education }}</span>
								<!-- <span>{{item.user.school[0].education}}</span> -->
								<span>{{ item.user.school[0].school_name }}</span>
							</div>
							<div>{{ item.user.school[0].major }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="talent-right row-center">
				<!-- 根据判断 继续沟通 -->
				<div class="bg-blue text-white flex-center pointer" v-if="heardTabIndex !== 1" :class="heardTabIndex == 3 && item.link_status == 1 ? 'manage-botton-one' : ''"
					@click="sendClick(item.type)">
					<span v-if="heardTabIndex == 3">{{item.link_status == 0 ? "立即聊" : "继续沟通"}}</span>
					<span v-if="heardTabIndex != 3">立即聊</span>
				</div>
				<div class="text-blue font-normal flex-center manage-botton pointer" v-if="heardTabIndex == 1" @click="submitClick('/layout/interactive',item.user_id,item.position,item.type,item.position_id)">
					继续沟通
				</div>
				<div class="bg-blue text-white flex-center pointer"
					:class="item.user.privates.protect_phone == 1 ? 'privacy-class' : ''"
					@mouseenter="mouseenter(item.type)" @mouseleave="mouseleave" @click="mouseClick(item.type)">
					<div class="position-relative">
						<span>电话沟通</span>
						<div class="position-absolute privacy" v-if="privacy && index == mouseenterIndex">
							<img src="../../../assets/glxt/19.png" alt="" />
							<div class="privacy-text font-normal position-absolute left-0 top-0">
								对方开启隐私保护，无法电话沟通，请使用聊天功能
							</div>
						</div>
					</div>
				</div>
				<div class="bg-blue text-white flex-center pointer" @click="delectClick(item.type)"
					v-if="heardTabIndex !== 2">
					{{ item.type == 1 ? "忽略" : "不合适" }}
				</div>
				<div class="bg-blue text-white flex-center pointer" @click="delectClick(item.type)"
					v-if="heardTabIndex== 2">
					{{ item.type == 1 ? "取消忽略" : "取消不合适" }}
				</div>
			</div>
		</div>
		<mask-model ref="maskModel" :imgShow="true" title="联系电话" width="482px" :btnCancel="false">
			<div class="privacy-phone text-main font-lighter">
				{{ phone ? phone : "暂无联系方式" }}
			</div>
		</mask-model>
		<mask-model ref="maskHint" title="温馨提示" sureContent="去认证" :btnCancel="false" width="482px"
			@submitClick="submitClick('/layout/account')">
			<div class="privacy-hint text-main font-normal">
				系统检测到您还未进行企业认证，认证后即可查看人才信息
			</div>
		</mask-model>
	</div>
</template>

<script>
	import maskModel from "../../../components/mask.vue";
	import {
		mapState
	} from "vuex";
	import api from "../../../api/user";
	import entApi from "../../../api/enterprises";
	import TIM from "tim-js-sdk";
	export default {
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["userDetail", "entDetail"]),
		},
		props: {
			type: {
				type: String,
			},
			index: {
				type: Number,
			},
			item: {
				type: Object,
			},
			heardTabIndex: {
				type: [String, Number],
			},
			content: {
				type: String,
			},
		},
		components: {
			maskModel
		},

		data() {
			return {
				privacy: false,
				mouseenterIndex: "",
				certificationShow: false,
				phone: "",
				avatar_url: require("@/assets/login/922028.png"),
			};
		},

		created() {
			// console.log(this.heardTabIndex);
		},

		mounted() {},

		methods: {
			sendClick(type) {
				if (type == 3) {
					return;
				}
				if (this.heardTabIndex == 3 && this.item.link_status == 1) {
					this.$router.push({
						path: "/layout/interactive",
						query: {
							id: this.item.user_id
						},
					});
					return;
				}
				let query = {
					user_id: this.item.user_id, //用户id
					user: this.item.user.user_name, //用户名称
					head: this.item.user.avatar_url, //用户头像
					ent_name: this.entDetail.ent_name, //企业名称
					ent_id: this.entDetail.id, //企业id
					ent_logo: this.entDetail.logo_url, //企业logo
					contacts: this.userDetail.user_name, //企业hr名字
					jopId: this.item.position_id, //工作id
					jop: this.item.position, //职位名称
					customStatus: 1, //沟通状态
					id: this.userDetail.id + "_2", //hrid
				};
				let message = this.$tim.createCustomMessage({
					to: this.item.user_id.toString(),
					conversationType: TIM.TYPES.CONV_C2C,
					payload: {
						data: "text",
						description: this.content,
						extension: JSON.stringify(query),
					},
				});
				// 2. 发送消息
				let promise = this.$tim.sendMessage(message);
				promise
					.then((imResponse) => {
						this.$router.push({
							path: "/layout/interactive",
							query: {
								id: this.item.user_id
							},
						});
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
				this.setLinkStatue(1);
			},
			// 鼠标移入
			mouseenter(type) {
				if (type == 3) return;
				if (this.item.user.privates.protect_phone == 0) return;
				this.mouseenterIndex = this.index;
				this.privacy = true;
			},
			// 鼠标移除
			mouseleave() {
				this.privacy = false;
			},
			// 鼠标点击
			mouseClick(type) {
				if (type == 3) return;
				if (this.item.user.privates.protect_phone == 1) return;
				// 判断是否已经认证
				if (this.entDetail.is_auth == 1) {
					api.userPhone({
						user_id: this.item.user_id
					}).then((res) => {
						this.phone = res.data.phone;
						this.$refs.maskModel.show();
					});
				} else {
					this.$refs.maskHint.show();
				}
			},
			// 去认证
			submitClick(path, id, position, type, position_id) {
				if (type == 3) {
					return;
				}
				if (path == "/layout/mangeCv" && this.entDetail.is_auth != 1) {
					this.$refs.maskHint.show();
					return;
				} else if (
					path == "/layout/mangeCv" &&
					this.item.user.privates.resume_status == 3
				) {
					this.$util.msg("人才已设置隐藏简历", "warning");
					return;
				}
				if (id) {
					this.$router.push({
						path,
						query: {
							id,
							position,
							type,
							position_id
						}
					});
					return;
				}
				this.$router.push({
					path
				});
			},
			setLinkStatue(link_status) {
				entApi
					.setLineStatue({
						position_id: this.item.position_id,
						link_status,
						user_id: this.item.user_id,
					})
					.then((res) => {
						if (res.code == 200) {
							this.$util.msg(res.msg);
							this.$emit("setLinkStatue");
						}
					});
			},
			// 忽略
			delectClick(type) {
				if (type == 3) return;
				let text = "";
				type == 1 ? (text = "确定忽略该人才") : (text = "确定设为不合适");
				let num = 2
				this.heardTabIndex == 2 ? num = 1 : num = 2
				this.$confirm(`${text}, 是否继续?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.setLinkStatue(num);
					})
					.catch(() => {});
			},
		},
	};
</script>
<style lang="less" scoped>
	.talent-recommend {
		padding-bottom: 0 !important;
	}

	.talent {
		width: 100%;
		background: #ffffff;
		border: 1px solid #f0f0f0;
		box-shadow: 0px 1px 9px 0px rgba(163, 162, 163, 0.26);
		border-radius: 4px;
		padding: 24px 32px 20px 31px;
		justify-content: space-between;
		color: #444;

		.user-avatar {
			width: 2.875rem;
			height: 2.875rem;
			margin-right: 2.4375rem;
			margin-top: 7px;
			border-radius: 50%;
		}

		.user-detail {
			position: relative;

			.el-example-img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: 100px;
				height: 100px;
				z-index: 2;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.user-name {
				margin-bottom: 6px;

				div {
					width: 79px;
					height: 24px;
					border: 1px solid #126bf9;
					border-radius: 4px;
					margin: 0 10px;
				}

				span:last-child {
					color: #999999;
					font-size: 13px;
				}
			}

			.user-year-job {
				margin-bottom: 9px;

				div {
					margin: 0 4px;
				}
			}

			.talent-expect {
				margin-bottom: 27px;

				span:first-child {
					color: #777;
				}

				div {
					margin: 0 4px;
				}
			}
		}

		.talent-middle {
			.work-talent {
				justify-content: flex-start;
				margin-bottom: 10px;

				img {
					width: 13px;
					height: 13px;
					margin-top: 3px;
					margin-right: 10px;
				}

				.work-time {
					align-items: flex-start;
					max-width: 1000px;

					div:first-child {
						min-width: 118px;
						color: #777777;
					}

					div:nth-child(2) {
						width: 156px;
						max-width: 156px;
						padding-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 1;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						white-space: normal;
					}

					div:last-child {
						max-width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 1;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						white-space: normal;
						margin-left: 20px;
					}
				}
			}
		}

		.talent-right {
			>div {
				width: 100px;
				height: 33px;
				margin-left: 10px;
			}

			.privacy-class {
				background: rgba(18, 107, 249, 0.5);
			}

			.manage-botton {
				border: 1px solid #126bf9;
			}

			.manage-botton-one {
				border: 1px solid #126bf9;
				background: #ffffff;
				color: #126bf9;
			}

			.privacy {
				top: -100px;
				left: -100px;
				width: 15.9375rem;
				height: 6.5625rem;

				.privacy-text {
					width: 100%;
					height: 100%;
					color: #9fa3b0;
					line-height: 17px;
					padding: 29px 38px 46px 35px;
					text-align: center;
				}
			}
		}
	}

	.privacy-phone {
		padding: 20px 0 26px 0;
	}

	.privacy-hint {
		padding: 17px 0 30px 0;
	}
</style>
